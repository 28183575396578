<template>
  <div class="wrapper">
    <admin-title title="积分设置"></admin-title>
    <el-form ref="form" :model="pointsIntroData" label-width="100px">
      <el-form-item label="积分名称">
        <el-input style="width:500px;" size="small" v-model="pointsIntroData.values"></el-input>
      </el-form-item>
      <el-form-item label="积分说明">
        <el-input style="width:500px;" type="textarea" :rows="4" v-model="pointsIntroData.describe"></el-input>
        <el-button type="primary" size="small" style="margin-left:20px;" @click="savePointsIntro">保存</el-button>
      </el-form-item>
    </el-form>
    <admin-title title="积分设置列表"></admin-title>

    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="page_size" />
  </div>
</template>

<script>
import { getListAPI, getPointsIntroAPI, editPointsIntroAPI } from './api'
const columns = [
  {
    label: '规则标题',
    prop: 'name',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['name']}</p>
    }
  },
  {
    label: '规则描述',
    prop: 'desc',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['desc']}</p>
    }
  },
  {
    label: '规则积分',
    prop: 'integral',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['integral']}</p>
    }
  },
  {
    label: '状态',
    prop: 'integral',
    minWidth: '120',
    customRender(h, row) {
      return (
        <p>
          <el-tag size="small" v-show={row['status'] == 1} type="primary">
            启用
          </el-tag>
          <el-tag size="small" v-show={row['status'] == 0} type="danger">
            禁用
          </el-tag>
        </p>
      )
    }
  },

  {
    label: '操作',
    width: '200',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            style="margin:0 10px 0 0"
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'PointsEdit', params: { id: row['id'] } })}
          >
            配置
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',

  data() {
    return {
      columns,
      currentPage: 1,
      page_size: 10,
      total: 0,
      list: [],
      pointsIntroData: {
        describe: '',
        values: ''
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    page_size() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
    this.getPointsIntro()
  },

  methods: {
    async getPointsIntro() {
      this.pointsIntroData = await getPointsIntroAPI()
    },
    savePointsIntro() {
      editPointsIntroAPI(this.pointsIntroData).then(() => {
        this.getPointsIntro()
        this.$message.success('保存成功')
      })
    },
    // 配置列表
    async getList() {
      console.log(222)
      // const { name } = this.searchData
      let params = { page: this.currentPage, page_size: this.page_size }
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    handleDelete({ id }) {
      this.$confirm('此操作将永久删除该优惠券, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 10px;
        .el-input {
          width: 250px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 20px;
  }
}
</style>
